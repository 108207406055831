import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { useSpring, animated } from 'react-spring'

import Header from './header'
import Footer from './footer'

const Layout = ({ children }) => {
  const fade = useSpring({
    from: { opacity: 0 },
    opacity: 1,
    config: { mass: 3, friction: 42, clamp: true },
    delay: 350
  })

  return (
    <>
      <Header />
      <S.Content style={fade}>
        <main>{children}</main>
        <Footer />
      </S.Content>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired
}

export default Layout

const S = {}

S.Content = styled(animated.div)`
  max-width: 1080px;
  margin: auto;
  padding: 0 6vw;
`

import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Link } from 'gatsby'

import colors from '../utils/theme/colors'

const NavLink = ({ children, to }) => {
  return (
    <S.Link to={to === '/' ? '/' : `/${to}`} activeClassName='active'>
      {children}
    </S.Link>
  )
}

export default NavLink

NavLink.propTypes = {
  children: PropTypes.node.isRequired,
  to: PropTypes.string.isRequired
}

const S = {}

S.Link = styled(Link)`
  color: ${() => colors.gray4};
  text-transform: uppercase;
  text-decoration: none;
  margin: 0 1rem;
  font-weight: bold;
  font-family: 'Proza Libre';
  transition: color 100ms ease-in-out;

  &.active {
    color: ${() => colors.purpleDark};
  }
  :hover {
    color: ${() => colors.redDark};
  }
`

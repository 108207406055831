export default {
  purple: '#2F2F4D',
  purpleDark: '#1F1F3D',
  purpleLight: '#3F3F5D',
  red: '#E03A69',
  redDark: '#D02A59',
  redLight: '#F04A79',
  green: '#409B29',
  greenDark: '#308B19',
  greenLight: '#50AB39',
  blue: '#2097AC',
  blueDark: '#10879C',
  blueLight: '#30A7BC',
  white: '#FFFFFF',
  gray0: 'rgba(0, 0, 0, 0.05)',
  gray1: 'rgba(0, 0, 0, 0.1)',
  gray2: 'rgba(0, 0, 0, 0.2)',
  gray3: 'rgba(0, 0, 0, 0.3)',
  gray4: 'rgba(0, 0, 0, 0.6)',
  gray5: 'rgba(0, 0, 0, 0.75)',
  gray6: 'rgba(0, 0, 0, 0.9)'
}

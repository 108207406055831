import React from 'react'
import styled from 'styled-components'
import { useStaticQuery, graphql } from 'gatsby'

import NavLink from '../elements/NavLink'
import colors from '../utils/theme/colors'

const Header = () => {
  const {
    site: { navigationItems }
  } = useStaticQuery(graphql`
    {
      site: sanitySiteSettings {
        id
        navigationItems: _rawMainNavigation(resolveReferences: { maxDepth: 3 })
      }
    }
  `)

  return (
    <S.Header>
      <S.Container>
        <S.Nav>
          {navigationItems.map(navItem => (
            <NavLink key={navItem.id} to={navItem.slug.current}>
              {navItem.page.title}
            </NavLink>
          ))}
        </S.Nav>
      </S.Container>
    </S.Header>
  )
}

Header.propTypes = {}

Header.defaultProps = {
  siteTitle: ``
}

export default Header

const S = {}

S.Header = styled.header`
  background: ${() => colors.white};
`

S.Container = styled.div`
  padding: 1rem 0;
  display: flex;
  justify-content: center;
`

S.Nav = styled.nav`
  overflow: auto;
  white-space: nowrap;
  padding: 1rem 0;
`

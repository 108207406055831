import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import colors from '../utils/theme/colors'

const ExternalLink = ({ children, href, blank, ...props }) => {
  return (
    <S.Link
      href={href}
      target={blank ? '_blank' : ''}
      rel='noreferrer noopener nofollow'
      {...props}
    >
      {children}
    </S.Link>
  )
}

ExternalLink.propTypes = {
  children: PropTypes.node.isRequired,
  href: PropTypes.string.isRequired,
  blank: PropTypes.bool
}

ExternalLink.defaultProps = {
  blank: false
}

export default ExternalLink

const S = {}

S.Link = styled.a`
  color: ${() => colors.gray4};
  transition: color 100ms ease-in-out;

  :hover {
    color: ${() => colors.gray5};
  }
`

import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import styled from 'styled-components'
import { FaFacebook, FaLinkedinIn, FaInstagram } from 'react-icons/fa'

import ExternalLink from '../elements/ExternalLink'

const Footer = () => {
  const { someHandles } = useStaticQuery(graphql`
    {
      someHandles: sanitySiteSettings {
        facebook
        linkedin
        instagram
      }
    }
  `)

  const icons = {
    facebook: <FaFacebook />,
    linkedin: <FaLinkedinIn />,
    instagram: <FaInstagram />
  }

  const handles = Object.keys(someHandles).map(handle => ({
    type: handle,
    value: someHandles[handle],
    icon: icons[handle]
  }))

  return (
    <S.Footer>
      {handles.map(handle => {
        if (!handle.value) return null
        return (
          <ExternalLink key={handle.type} blank href={handle.value}>
            {icons[handle.type]}
          </ExternalLink>
        )
      })}
    </S.Footer>
  )
}

export default Footer

const S = {}

S.Footer = styled.footer`
  display: flex;
  justify-content: center;
  padding: 2rem;

  font-size: 1.5rem;

  a {
    margin: 0 1rem;
  }
`
